<template>
  <div class="addr-item">
    <AppIcon :name="`${item?.currency.toLowerCase()}-circle`" size="28px" />
    <div class="info">
      <TooltipCopy>
        <AppText variant="div">
          {{ item?.address }}
        </AppText>
      </TooltipCopy>
      <AppText variant="div" color="var(--color-black-04)">
        {{ amount }} {{ item?.currency }} received
      </AppText>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

import TooltipCopy from '@/components/TooltipCopy.vue';

import { useAdmin } from '@/composables/useAdmin';
import { addThousandsSeparators } from '@/utils/numberFormatter';

export default {
  name: 'MerchantDepositAddress',
  components: {
    TooltipCopy,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { numberFormat } = useAdmin();
    const amount = computed(() => addThousandsSeparators({ val: props.item?.totalReceived, separator: numberFormat.value }));

    return { amount };
  },
};
</script>

<style lang="scss" scoped>
.addr-item {
  display: flex;
  align-items: center;
  gap: 20px;

  .info {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
</style>
