<template>
  <div class="d-flex item-header">
    <AppAvatar size="40px" style="margin-right: 20px;" :src="info?.profilePicture?.crop" app-role="merchant" />
    <div>
      <AppText size="18px" class="font-medium" variant="div">
        {{ item.companyName }}
      </AppText>
      <div class="d-flex align-items-center mt-2">
        <TooltipCopy is-icon-hidden>
          <div class="uid-block">
            <AppIcon name="uid-circle" size="16px" class="mr-2" />
            <AppText size="12px" class="font-medium" :line-height="1.5">
              {{ item.uid }}
            </AppText>
          </div>
        </TooltipCopy>
        <DotSeparator color="var(--color-CCCCCC)" margin="0 12px" />
        <AppText
          class="font-medium status"
          :style="{
            '--color': color,
          }"
          :line-height="1"
          is-block
          mr="20px"
        >
          {{ t(STATUS[status]) }}
        </AppText>
        <SmallActionButton @action="onRefresh" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';

import AppAvatar from '@/components/Avatars/AppAvatar.vue';
import DotSeparator from '@/components/DotSeparator.vue';
import SmallActionButton from '@/components/Buttons/SmallActionButton.vue';
import TooltipCopy from '@/components/TooltipCopy.vue';

import { emitter } from '@/composables/useBus';
import { COLORS, STATUS } from '@/common/status';
import colors from '@/styles/colors';

export default defineComponent({
  components: {
    AppAvatar,
    DotSeparator,
    TooltipCopy,
    SmallActionButton,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    info: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['update'],
  setup(props, { emit }) {
    const status = computed(() => props.info?.status || props.item?.status);
    const type = computed(() => COLORS[status.value.toUpperCase()]);
    const color = computed(() => {
      if (status.value === 'DELETED') {
        return 'var(--color-939393)';
      }

      return colors[`t${type.value}`];
    });

    const onRefresh = () => {
      emitter.emit('update-table-data');
      emitter.emit('update-merchant-modal');
      emit('update');
    };

    return {
      status,
      color,
      STATUS,
      onRefresh,
    };
  },
});
</script>

<style scoped lang="scss">
.item-header {
  margin: 30px 40px 20px;
}

.uid-block {
  height: 26px;
  padding: 0 8px 0 6px;
  background: var(--color-F2F2F2);
  border-radius: 30px;
  @include flex-center;

  :deep(.text) {
    letter-spacing: 0.3em;
  }
}

.status {
  color: var(--color);
}
</style>
