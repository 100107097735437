<template>
  <ActionForm
    :request-fields="requestFields"
    :validation-schema="validationSchema"
    :response-errors="fieldsErrors"
    @validate="onValidate"
  >
    <template #default="{ errors, action }">
      <FInput
        v-model="requestFields.uid"
        placeholder="Merchant UID"
        size="big-medium"
        mask="onlyInteger"
        :validation-error="errors.uid"
        :maxlength="7"
        class="uid-input"
      >
        <template #prefix>
          <AppIcon name="uid-circle" size="25px" is-img-tag />
        </template>
        <template #append>
          <FButton
            size="x-small"
            type="inner"
            @click="generateRandomUid"
          >
            <AppIcon
              name="rotate-ccw-reload"
              size="12px"
              fill="var(--color-primary)"
              :class="{ rotate: isRotating }"
              style="margin-right: 8px;"
            />
            <AppText class="font-medium">
              Generate new
            </AppText>
          </FButton>
        </template>
      </FInput>

      <FInput
        v-model="requestFields.companyName"
        has-prefix
        placeholder="Merchant Name"
        :validation-error="errors.companyName"
        :maxlength="25"
        class="name-input"
      >
        <template #prefix>
          <AppIcon name="shop" size="18px" :opacity="0.4" is-img-tag />
        </template>
      </FInput>
      <FInput
        v-model="requestFields.email"
        has-prefix
        placeholder="Email Address"
        :validation-error="errors.email"
        class="email-input"
      >
        <template #prefix>
          <AppIcon
            name="mail"
            :opacity="0.4"
            size="18px"
            is-img-tag
          />
        </template>
      </FInput>

      <div class="d-flex" style="margin-top: 30px; gap: 20px;">
        <FSelect
          v-model="requestFields.plan"
          label="Plan"
          :options="planOptions"
          style="width: auto; flex: 1;"
          has-arrow
          popper-class="merchant-plan-select-popper"
          input-padding="42px"
          max-height="320px"
        >
          <template #prefix>
            <AppIcon :key="requestFields.plan" :name="`plan-${requestFields.plan?.toLowerCase()}`" size="25px" is-img-tag />
          </template>
          <template #option="{ item }">
            <div class="d-flex w-100 align-items-center">
              <AppIcon :name="`plan-${item.value?.toLowerCase()}`" size="25px" is-img-tag />
              <div class="ml-4">
                {{ item.text }}
              </div>
            </div>
          </template>
        </FSelect>
        <div style="flex: 1;">
          <FSelect
            v-if="!['FREE', 'IO'].includes(requestFields.plan)"
            v-model="requestFields.cycle"
            label="Cycle"
            :options="cycleOptions"
            style="width: auto;"
            has-arrow
          />
        </div>

        <div style="flex: 1;">
          <InputNumberStep
            v-if="!['FREE', 'IO'].includes(requestFields.plan) && requestFields.cycle !== 'LIFETIME'"
            v-model="requestFields.term"
            :max="requestFields.cycle === 'MONTHLY' ? 6 : 3"
            label="Term"
          />
        </div>
      </div>

      <AppAlert v-if="!['FREE', 'IO'].includes(requestFields.plan) && requestFields.cycle !== 'LIFETIME'">
        <AppText size="14px">
          Next renewal date will be <DateFormatter class="font-semibold" :date="nextRenewal" :format="dateFormat.value" />
        </AppText>
      </AppAlert>
      <AppAlert style="margin-bottom: 16px;">
        <AppIcon
          name="info-circle-medium"
          is-img-tag
          class="mr-2"
          size="18px"
          :opacity="0.4"
        />
        <AppText color="var(--color-black-04)">
          Account is pruned if the email is not verified within 24 hours
        </AppText>
      </AppAlert>

      <FButton :loading="isLoading" @click="action">
        Add Merchant
      </FButton>
    </template>
  </ActionForm>
</template>

<script>
import {
  computed, defineComponent, ref, watch,
} from 'vue';
import dayjs from 'dayjs';

import AppAlert from '@/components/AppAlert.vue';
import DateFormatter from '@/components/Helpers/DateFormatter.vue';
import InputNumberStep from '@/components/Inputs/InputNumberStep.vue';

import { ActionForm, useValidation, yup } from '@/validation';
import {
  emailRule, enumRule, nameRuleAsAdmin, uidAsSuperadmin, uidAsAdmin,
} from '@/validation/rules';
import { cycleSelectOptions, planSelectOptions } from '@/common/data';
import { useToken } from '@/composables/useToken';
import { useAdmin } from '@/composables/useAdmin';
import { randomInteger } from '@/utils/functions';

import { merchantApi } from './api';

export default defineComponent({
  components: {
    DateFormatter,
    AppAlert,
    ActionForm,
    InputNumberStep,
  },
  emits: ['success'],
  setup(props, { emit }) {
    const { initState, fieldsErrors } = useValidation();
    const { isSuperAdminRole } = useToken();

    const fields = {
      uid: '',
      email: '',
      companyName: '',
      plan: 'FREE',
      cycle: 'MONTHLY',
      term: 1,
    };

    const { requestFields } = initState(fields);
    const isIoPlan = computed(() => requestFields?.plan === 'IO');

    const uidRule = computed(() => (isSuperAdminRole.value && isIoPlan.value
      ? uidAsSuperadmin
      : uidAsAdmin
    ));

    const validationSchema = computed(() => yup.object().shape({
      uid: uidRule.value,
      email: emailRule,
      companyName: nameRuleAsAdmin,
      plan: enumRule,
      cycle: enumRule,
    }));

    const { inviteMerchant, isLoading } = merchantApi();

    const term = computed(() => {
      if (requestFields.cycle === 'LIFETIME' || ['FREE', 'IO'].includes(requestFields.plan)) {
        return undefined;
      }

      return requestFields.term;
    });

    const cycle = computed(() => {
      if (requestFields.plan === 'FREE') {
        return undefined;
      }

      if (requestFields.plan === 'IO') {
        return 'LIFETIME';
      }

      return requestFields.cycle;
    });

    const onValidate = async () => {
      const { isSuccess, errorObject } = await inviteMerchant({
        uid: requestFields.uid,
        email: requestFields.email,
        companyName: requestFields.companyName,
        plan: requestFields.plan,
        cycle: cycle.value,
        term: term.value,
      });

      if (isSuccess) {
        emit('success');
      }
      if (!isSuccess) {
        fieldsErrors.value = errorObject.fields;
      }
    };

    const generateRandomUid = () => {
      if (!isRotating.value) {
        isRotating.value = true;

        setTimeout(() => {
          isRotating.value = false;
        }, 300);
      }

      requestFields.uid = String(randomInteger(1000000, 9999999));
    };

    const nextRenewal = computed(() => dayjs().add(requestFields.term, requestFields.cycle === 'MONTHLY' ? 'month' : 'year').format());

    const cycleOptions = computed(() => {
      const isBlackSuperadminPlan = isSuperAdminRole.value && requestFields.plan === 'BLACK';
      const isProPlan = requestFields.plan === 'PRO';

      if (isBlackSuperadminPlan) {
        return [
          ...cycleSelectOptions,
          { text: 'Lifetime', value: 'LIFETIME' },
        ];
      }

      if (isProPlan) {
        return [cycleSelectOptions[1]];
      }

      return cycleSelectOptions;
    });

    const planOptions = computed(() => {
      if (isSuperAdminRole.value) {
        return planSelectOptions;
      }

      return planSelectOptions.filter((option) => option.value !== 'IO');
    });

    watch(computed(() => requestFields.plan), () => {
      requestFields.cycle = cycleOptions.value[0].value;
    });

    const isRotating = ref(false);

    const { dateFormat } = useAdmin();
    return {
      validationSchema,
      requestFields,
      fieldsErrors,

      isLoading,
      onValidate,

      generateRandomUid,
      planOptions,
      cycleOptions,
      nextRenewal,
      isRotating,
      dateFormat,
    };
  },
});
</script>

<style lang="scss" scoped>
.uid-input {
  :deep(.el-input__wrapper) {
    padding-left: 20px !important;
  }
  :deep(.el-input__prefix) {
    margin-right: 12px;
  }
  :deep(.el-input__inner) {
    letter-spacing: 6px;
    &::placeholder {
      letter-spacing: normal;
    }
  }
  :deep(.slot-append) {
    right: 22px;
  }

  .rotate {
    transition: transform linear 300ms;
    transform: rotate(-360deg);
  }
}

.name-input, .email-input {
  margin-top: 20px;
}

:global(.merchant-plan-select-popper .el-select-dropdown__wrap) {
  max-height: unset;
}

:global(.merchant-plan-select-popper .el-select-dropdown__item) {
  padding: 10px 16px;
}
</style>
