<template>
  <div class="addr-wrap">
    <MerchantDepositAddress :item="item" />
    <div v-if="items.length > 1" class="selector">
      <div class="selector-item" :class="{ disabled: itemIndex === (items.length - 1) }" @click="setIndex(itemIndex + 1)">
        <AppIcon name="arrow-bracket" class="rotate-270" :opacity="0.4" size="18px" />
      </div>
      <AppText variant="div" color="var(--color-black-04)">
        #{{ itemIndex + 1 }}
      </AppText>
      <div class="selector-item" :class="{ disabled: itemIndex === 0 }" @click="setIndex(itemIndex - 1)">
        <AppIcon name="arrow-bracket" class="rotate-90" :opacity="0.4" size="18px" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';

import MerchantDepositAddress from './MerchantDepositAddress.vue';

export default {
  name: 'MerchantDepositAddresses',
  components: {
    MerchantDepositAddress,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const itemIndex = ref(props.items.length - 1);
    const item = computed(() => props.items[itemIndex.value]);

    const setIndex = (index) => {
      if (index >= 0 && index <= props.items.length - 1) {
        itemIndex.value = index;
      }
    };

    return { item, itemIndex, setIndex };
  },
};
</script>

<style lang="scss" scoped>
.addr-wrap {
  height: 82px;
  padding: 0 24px;
  display: flex;
  align-items: center;

  & > :first-child {
    flex-grow: 1;
  }

  .selector {
    display: flex;
    align-items: center;
    gap: 8px;

    .selector-item {
      cursor: pointer;
      width: 24px;
      height: 24px;
      @include flex-center;
      background: var(--color-black-007);
      border-radius: 50%;

      :deep(.icon path) {
        stroke: var(--color-black-04);
      }

      &:hover {
        :deep(.icon) {
          opacity: 1;
        }
      }

      &.disabled {
        cursor: not-allowed;
        :deep(.icon) {
          opacity: 0.1;
        }
      }
    }
  }
}
</style>
