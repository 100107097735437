<template>
  <Transition name="show">
    <div v-if="!wasSuccessfullyCredited" class="full-height" style="padding: 30px 40px;">
      <AppText size="15px" class="font-medium" variant="div" mb="20px">
        Manually credit the merchant's deposit balance
      </AppText>
      <ActionForm
        :request-fields="requestFields"
        :validation-schema="validationSchema"
        :response-errors="fieldsErrors"
        style="position: relative"
        @validate="onPassedValidation"
        @input="onFieldsInput"
        @error="onValidationError"
      >
        <template #default="{ errors, action }">
          <div>
            <FInput
              ref="input"
              v-model="requestFields.credit"
              :validation-error="errors.credit"
              class="min-amount"
              mask="onlyInteger"
              maxlength="6"
              placeholder="10"
            >
              <template #prefix>
                <AppText
                  size="20px"
                  :line-height="1.5"
                  variant="div"
                  color="var(--color-black)"
                >
                  $
                </AppText>
              </template>
            </FInput>
            <FInput
              v-model="requestFields.note"
              :validation-error="errors.note"
              class="memo"
              type="textarea"
              maxlength="120"
              show-word-limit
              mask="all"
              placeholder="Credit note (transaction hash or reason)"
            />

            <FSpacer style="margin-bottom: 28px;" />

            <FSlider ref="slider" :error="hasError" @complete="action" />
          </div>
        </template>
      </ActionForm>
    </div>
    <div v-else class="flex-center flex-column" style="padding-top: 60px;">
      <CheckIcon style="margin-bottom: 20px;" />
      <AppText size="15px" class="font-medium">
        ${{ requestFields.credit }} successfully credited
      </AppText>
    </div>
  </Transition>
</template>

<script>
import { defineComponent, ref } from 'vue';

import CheckIcon from '@/components/Icon/CheckIcon.vue';

import { useBus } from '@/composables/useBus';
import { ActionForm, useValidation, yup } from '@/validation';

import { creditMerchant } from './api';

export default defineComponent({
  components: {
    CheckIcon,
    ActionForm,
  },
  props: {
    merchantId: {
      type: String,
      default: '',
    },
  },
  emits: ['success', 'complete'],
  setup(props, { emit }) {
    const { initState, fieldsErrors } = useValidation();

    const fields = {
      credit: null,
      note: '',
    };

    const validationSchema = yup.object().shape({
      credit: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? 0 : value))
        .required('isRequired')
        .min(1, 'notLessThan'),
      note: yup
        .string()
        .required('isRequired')
        .min(20, 'notLessThan')
        .max(120, 'notMoreThan'),
    });

    const { requestFields } = initState(fields);

    const wasSuccessfullyCredited = ref(false);

    const slider = ref(null);
    const onPassedValidation = async () => {
      const { isSuccess } = await creditMerchant(props.merchantId, {
        credit: Number(requestFields.credit),
        note: requestFields.note,
      });

      if (isSuccess) {
        await slider.value.complete({ time: 600 });
        wasSuccessfullyCredited.value = true;
        emit('complete');
      }
    };

    const { emitHandler } = useBus();
    emitHandler('update-table-data', () => {
      requestFields.credit = null;
      requestFields.note = '';
      wasSuccessfullyCredited.value = false;
    });

    const hasError = ref(false);
    const onValidationError = () => {
      hasError.value = true;
      setTimeout(() => { hasError.value = false; }, 0);
    };
    const onFieldsInput = () => { hasError.value = false; };

    return {
      requestFields,
      validationSchema,
      fieldsErrors,
      onPassedValidation,

      hasError,
      onValidationError,
      onFieldsInput,

      slider,

      wasSuccessfullyCredited,
    };
  },
});
</script>

<style scoped lang="scss">
.min-amount {
  width: 200px;
  margin-bottom: 20px;
  :deep(.el-input__inner), :deep(.el-input__prefix .text) {
    font-size: 17px;
    line-height: 1.5;
    // caret-color: var(--color-primary);
  }
}
</style>
