<template>
  <PageTable
    ref="merchantsPage"
    v-model:item-modal="isItemModalOpen"
    :columns="status === 'PRUNED' ? prunedColumns : columns"
    :statuses="statuses"
    :filters="filters"
    :module-name="moduleName"
    :api-path="apiPath"
    :event-name="eventName"
    :is-info-modal-active="isInfoModalActive"

    :create-params="{
      title: 'Add merchant',
      isDescriptionVisible: false,
      hasHeaderDivider: false,
      width: '660px',
    }"

    :info-params="{
      isDescriptionVisible: false,
      hasBodyPaddings: false,
      hasHeader: false,
      width: '660px',
    }"

    button-add-text="Add merchant"
    @data-loaded="onDataLoaded"
  >
    <template #main>
      <div class="overlay-wrapper">
        <PaginationTable
          ref="paginationTable"
          class="overlay-item"
          :class="{
            'is-items-deleted': ['DELETED', 'PRUNED'].includes(currentStatus),
          }"
          :module-name="moduleName"
          :api-path="apiPath"
          :columns="columns"
          :is-info-modal-active="isInfoModalActive"
          @row-click="onRowClick"
          @grid-ready="onGridReady"
        />
      </div>
    </template>
    <template #status-aside>
      <FRadioButton
        v-slot="option"
        v-model="status"
        :options="[{ text: 'Pruned', value: 'PRUNED' }]"
        class="pruned-button"
        style="min-width: 118px;"
      >
        <AppIcon
          name="brush-clean"
          class="pruned-icon"
          size="14px"
          is-img-tag
        />

        <AppText style="padding: 0 10px;">
          {{ t(`status.${option.value}`) }}
        </AppText>

        <AppTooltip
          width="250px"
          text-align="left"
          cursor="default"
          style="flex-shrink: 0;"
        >
          <template #text>
            View merchants truncated due to incomplete registration.
          </template>
          <AppIcon
            name="info-circle-medium"
            size="18px"
            :opacity="0.4"
            is-img-tag
          />
        </AppTooltip>
      </FRadioButton>

      <FSpacer />
    </template>

    <template #item="{ item }">
      <MerchantItem :item="item" :is-open="isItemModalOpen" @success="onSuccess" />
    </template>

    <template #create>
      <MerchantCreate @success="onCreateSuccess" />
    </template>
  </PageTable>
</template>

<script setup>
import {
  computed, ref, watch,
} from 'vue';

import PageTable from '@/components/Modules/PageTable';
import PaginationTable from '@/components/Modules/DataTable/PaginationTable.vue';
import AppTooltip from '@/components/AppTooltip.vue';

import { usePagination } from '@/composables/usePagination';

import {
  columns, filters, statuses, prunedColumns,
} from './data';
import MerchantCreate from './MerchantCreate.vue';
import MerchantItem from './MerchantItem';

defineEmits(['title']);

const moduleName = 'adminMerchants';
const apiPath = '/merchant-admin/list';
const eventName = 'update-merchants-page';

const merchantsPage = ref(null);
const onCreateSuccess = () => {
  merchantsPage.value.getFreshData();
};

const isItemModalOpen = ref(false);

const onSuccess = (params = {}) => {
  if (params.shouldCloseModal) {
    merchantsPage.value.closeItemModal();
  }
  merchantsPage.value.getFreshData();
};

const { status, isLoading } = usePagination(apiPath, { moduleName });

const isInfoModalActive = computed(() => status.value !== 'PRUNED');

const onRowClick = (item) => {
  merchantsPage.value.onRowClick(item);
};

const currentStatus = ref(status.value);

const onDataLoaded = (s) => {
  currentStatus.value = s;
};

const paginationTable = ref(null);

const shouldColumnsToBeUpdated = ref(false);
watch(status, (newstatus, oldStatus) => {
  if ([newstatus, oldStatus].includes('PRUNED')) {
    shouldColumnsToBeUpdated.value = true;
  }
});

watch(isLoading, () => {
  if (isLoading.value === false && shouldColumnsToBeUpdated.value) {
    paginationTable.value.setNewColumns(status.value);
    shouldColumnsToBeUpdated.value = false;
  }
});

const onGridReady = () => {
  isLoading.value = true;
  paginationTable.value.setNewColumns(status.value);
  isLoading.value = false;
};
</script>

<style lang="scss" scoped>
:deep(.pruned-button) {
  margin-left: 12px;
  position: relative;

  &>:before {
    content: '';
    position: absolute;
    top: 8px;
    left: -12px;
    width: 1px;
    height: 20px;
    background-color: var(--color-E5E5E5);
  }

  &:hover {
    .pruned-icon {
      opacity: 1;
    }
  }

  .pruned-icon {
    opacity: 0.4;
    @include transition-base(opacity);
  }
}

:deep(.pruned-button .el-radio-button__inner) {
  padding: 0 12px !important;
}

.is-items-deleted {
  :deep(.ellipsis) {
    opacity: 0.4;

    &.is-deleted-status {
      opacity: 1;
    }
  }
}
</style>
